import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchSendClearCache,
} from "./mainAPI";


const initialState = {
  drawerOpen: false,
  currentPage: "patients", // patients, protocolGroups, protocolItem
  currentMenuItem: "home", // settings
  loading: {
    clearCache: false,
  },
};

export const sendClearCache = createAsyncThunk(
  "pages/sendClearCache",
  async (props, { getState }) => {

    // const { success } = props;


    // const { auth } = getState();
    const response = await fetchSendClearCache({
      data: {

      },
      // success
    });

    return response;
  }
);

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setCurrentMenuItem: (state, action) => {
      state.currentMenuItem = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(sendClearCache.pending, (state) => {
        state.status = "loading";
        state.loading.clearCache = true;
      })

      .addCase(sendClearCache.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.clearCache = false;
      })

      .addCase(sendClearCache.rejected, (state, action) => {
        state.status = "idle";
        state.loading.clearCache = false;
      })

  }
});

export const {
  setDrawerOpen,
  setCurrentPage,
  setCurrentMenuItem,
} = mainSlice.actions;

export const selectDrawerOpen = (state) => state.main.drawerOpen;
export const selectCurrentPage = (state) => state.main.currentPage;
export const selectCurrentMenuItem = (state) => state.main.currentMenuItem;
export const selectLoading = (state) => state.main.loading;

export default mainSlice.reducer;
