import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  fetchGetPatientsList,
  fetchGetProtocolGroups,
  fetchGetPattern,
  fetchWriteProtocol,
  fetchReplaceProtocol,
} from "./pagesAPI";

import { appStorage } from "helpers";

// import { mockUp1 } from "./mockUp1";

const EMPTY_PROTOCOL = {
  libId: 0,
  publicId: "", // можно заполнить (это id группы)
  name: "",
  text: "",
  events: [],
  satelliteData: null,
  date: null,
  status: -100500, // потом поменять на 0
  clinic: null,
  department: null,
  author: null,
  patient: {},// можно заполнить
  headerId: "new",
  type: 0, // можно заполнить?
  canEdit: true,
}

const initialState = {
  patientsList: [],
  currentPatientId: null,
  currentProtocolGroupId: null,
  currentProtocolId: null,
  editedProtocol: {},
  patientProtocols: {},
  findedProtocols: {},
  protocolMode: "view", // edit, view
  protocolPatern: { elements: [] },
  settings: {
  },
  loading: {
    patients: false,
    protocolItem: false,
  },

  LS_patientProtocols: {},



  clarificationData: {
    hasParameters: false,
    hasLocalization: true,
    hasСharacteristics: true,
    parametersList: [],
  },
  currentSymptom: null,
  symptomsList: [
    // {
    //   key: null,
    //   id: "140790025",
    //   fpid: null,
    //   type: "Nozology",
    //   level: 1102,
    //   name: "Боль за грудиной",
    //   namesearch: null,
    //   group: 0,
    //   main: false,
    //   code: null,
    //   nameshort: null,
    // },
    {
      id: "12hg3h4lfd",
      value: 0.0,
      unitId: null,
      unitname: null,
      name: "Боль",
      clarifications: [
        {
          name: "пульсирующая",
          unitname: null,
          id: "1sdfgsd3jh343j",
          value: 0,
          unitId: null,
          type: "Details",
        },
        {
          name: "постоянно",
          unitname: null,
          id: "2sdfgsd3jh343j",
          value: 0,
          unitId: null,
          type: "Details",
        },
        {
          name: "рука",
          unitname: null,
          id: "4u5KHwCUUN3IVsbACME+/g==",
          value: 0,
          unitId: null,
          type: "Localization",
        },
        {
          name: "нога",
          unitname: null,
          id: "4sdfgsd3jh343j",
          value: 0,
          unitId: null,
          type: "Localization",
        },
      ]

    },
    {
      id: "512hg3h4lfd",
      value: 0.0,
      unitId: null,
      unitname: null,
      name: "Высыпания",
      clarifications: [
        {
          name: "болезненное при надавливании",
          unitname: null,
          id: "2sdfgsd3jh343j",
          value: 0,
          unitId: null,
          type: "Details",
        },
        {
          name: "рука",
          unitname: null,
          id: "3sdfgsd3jh343j",
          value: 0,
          unitId: null,
          type: "Localization",
        },
        {
          name: "живот",
          unitname: null,
          id: "4sdfgsd3jh343j",
          value: 0,
          unitId: null,
          type: "Localization",
        },
        {
          name: "лицо",
          unitname: null,
          id: "54sdfgsd3jh343j",
          value: 0,
          unitId: null,
          type: "Localization",
        },
      ]

    },

  ],
};



export const sendWriteProtocol = createAsyncThunk(
  "pages/sendWriteProtocol",
  async (props, { getState }) => {

    const { data, success } = props;

    const { auth } = getState();
    const response = await fetchWriteProtocol({
      data: {
        authKey: auth.authKey,
        ...data,
      },
      success
    });

    return response;
  }
);

export const sendReplaceProtocol = createAsyncThunk(
  "pages/sendReplaceProtocol",
  async (props, { getState }) => {

    const { data, success } = props;

    const { auth } = getState();
    const response = await fetchReplaceProtocol({
      data: {
        authKey: auth.authKey,
        ...data,
      },
      success
    });

    return response;
  }
);

export const sendGetPatientsList = createAsyncThunk(
  "pages/sendGetPatientsList",
  async (props, { getState }) => {

    const { success } = props;

    const { auth } = getState();
    const response = await fetchGetPatientsList({
      data: {
        authKey: auth.authKey,
      },
      success
    });

    return response;
  }
);

export const sendGetProtocolGroups = createAsyncThunk(
  "pages/sendGetProtocolGroups",
  async (props, { getState }) => {

    const { success, data = {} } = props;
    const { patientId = "" } = data;

    const { auth } = getState();
    const response = await fetchGetProtocolGroups({
      data: {
        authKey: auth.authKey,
        patientId
      },
      success
    });

    return response;
  }
);

export const sendGetPattern = createAsyncThunk(
  "pages/sendGetPattern",
  async (props, { getState }) => {

    const { success = () => { } } = props || {};
    const { auth, pages } = getState();

    console.log({
      data: {
        authKey: auth.authKey,
        protocolGroupId: pages.currentProtocolGroupId,
      },
      // success
    });

    const response = await fetchGetPattern({
      data: {
        authKey: auth.authKey,
        protocolGroupId: pages.currentProtocolGroupId,
      },
      success
    });

    return response;
  }
);


export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {

    addToSymptomsList: (state, action) => {
      state.symptomsList = [
        ...state.symptomsList,
        action.payload
      ];
    },



    setSetting: (state, action) => {
      const { name, value } = action.payload;
      state.settings[name] = value;

      appStorage.setItem("settings", state.settings);
    },

    setSettings: (state, action) => {
      state.settings = action.payload;
    },

    setProtocolMode: (state, action) => {
      state.protocolMode = action.payload;
    },

    setPatient: (state, action) => {
      state.patient = action.payload;
    },

    setPatientEdit: (state, action) => {
      state.patientEdit = action.payload;
    },

    resetSettings: (state) => {
      state.settings = initialState.settings;
      appStorage.removeItem("settings");
    },
    resetPatient: (state) => {
      state.patient = initialState.patient;
    },
    resetPatientEdit: (state) => {
      state.patientEdit = initialState.patientEdit;
    },

    addToPatientDisease: (state, action) => {
      state.patient.disease = [
        ...state.patient.disease,
        action.payload
      ];
    },

    addToPatientEditDisease: (state, action) => {
      state.patientEdit.disease = [
        ...state.patientEdit.disease,
        action.payload
      ];
    },

    addToClarificationsList: (state, action) => {
      state.symptomsList = state.symptomsList.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    addToEventsList: (state, action) => {
      // const currentState = current(state);

      // const currentGroup = currentState.patientProtocols[currentState.currentPatientId]?.protocolGroups?.find(protocolGroup => protocolGroup.id === currentState.currentProtocolGroupId) || [];
      // const currentProtocol = currentGroup?.data.find(protocol => protocol.headerId === currentState.currentProtocolId) || {};

      // console.log(currentProtocol);

      state.editedProtocol.events.push(action.payload);
    },

    updateClarificationList: (state, action) => {
      console.log("action.payload.mixedArr", action.payload.mixedArr);

      if (action.payload.mixedArr === null) {
        state.editedProtocol.events[state.currentSymptom].clarifications.splice(action.payload.rowIndex, 1);
      } else {
        state.editedProtocol.events[state.currentSymptom].clarifications[action.payload.rowIndex] = action.payload.mixedArr;
      }
    },

    removeFromEventsList: (state, action) => {
      // const currentState = current(state);

      // const currentGroup = currentState.patientProtocols[currentState.currentPatientId]?.protocolGroups?.find(protocolGroup => protocolGroup.id === currentState.currentProtocolGroupId) || [];
      // const currentProtocol = currentGroup?.data.find(protocol => protocol.headerId === currentState.currentProtocolId) || {};

      // const events = currentProtocol.events;
      console.log(action.payload);
      state.editedProtocol.events.splice(action.payload, 1);

      // console.log(events);
      // state.editedProtocol = {};

    },

    addToPatientClarificationsList: (state, action) => {
      state.patient.disease = state.patient.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    addToPatientEditClarificationsList: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    removeClarificationById: (state, action) => {
      state.symptomsList = state.symptomsList.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removePatientClarificationById: (state, action) => {
      state.patient.disease = state.patient.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removePatientEditClarificationById: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removeSymptomById: (state, action) => {
      state.symptomsList = state.symptomsList.filter(item => item.id !== action.payload);
    },

    removePatientDiseaseById: (state, action) => {
      state.patient.disease = state.patient.disease.filter(item => item.id !== action.payload);
    },

    removePatientEditDiseaseById: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.filter(item => item.id !== action.payload);
    },
    resetSymptomsList: (state) => {
      state.symptomsList = initialState.symptomsList;
    },
    setCurrentSymptom: (state, action) => {
      state.currentSymptom = action.payload;
    },
    setCurrentPatientDisease: (state, action) => {
      state.currentPatientDisease = action.payload;
    },
    setCurrentPatientId: (state, action) => {
      state.currentPatientId = action.payload;
    },
    setEditedProtocol: (state, action) => {
      state.editedProtocol = action.payload;
    },
    setCurrentProtocolId: (state, action) => {
      state.currentProtocolId = action.payload;
    },
    setCurrentProtocolGroupId: (state, action) => {
      state.currentProtocolGroupId = action.payload;
    },
    updateHeaderId: (state, action) => {
      console.log(state);
      console.log(action);
      console.log(action.payload);


    },
    resetCurrentSymptom: (state) => {
      state.currentSymptom = initialState.currentSymptom;
    },
    resetCurrentPatientDisease: (state) => {
      state.currentPatientDisease = initialState.currentPatientDisease;
    },

    resetByKeys: (state, action) => {
      action.payload.forEach(key => {
        state[key] = initialState[key];
      });
    },


  },

  extraReducers: (builder) => {
    builder

      .addCase(sendGetPatientsList.pending, (state) => {
        state.status = "loading";
        state.loading.patients = true;
      })

      .addCase(sendGetPatientsList.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.patients = false;

        let data = action.payload?.result?.patients || initialState.patientsList;

        state.patientsList = data;
      })

      .addCase(sendGetPatientsList.rejected, (state, action) => {
        state.status = "idle";
        state.loading.patients = false;
      })




      .addCase(sendGetProtocolGroups.pending, (state) => {
        state.status = "loading";
        state.loading.patients = true;
      })

      .addCase(sendGetProtocolGroups.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.patients = false;

        state.patientProtocols = action.payload?.result?.patientProtocols || initialState.patientProtocols;
        state.findedProtocols = action.payload?.result?.findedProtocols || initialState.findedProtocols;
      })

      .addCase(sendGetProtocolGroups.rejected, (state, action) => {
        state.status = "idle";
        state.loading.patients = false;
      })




      .addCase(sendGetPattern.pending, (state) => {
        state.status = "loading";
        state.loading.protocolItem = true;
      })

      .addCase(sendGetPattern.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.protocolItem = false;

        state.protocolPatern = action.payload?.result || initialState.protocolPatern;
        // state.protocolPatern = mockUp1.result || initialState.protocolPatern;
      })

      .addCase(sendGetPattern.rejected, (state, action) => {
        state.status = "idle";
        state.loading.protocolItem = false;
      })




      .addCase(sendWriteProtocol.pending, (state) => {
        state.status = "loading";
        state.loading.protocolItem = true;
      })

      .addCase(sendWriteProtocol.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.protocolItem = false;

        let publicId = action.meta.arg.data?.protocol?.publicId;
        let newHeaderId = action.payload.result?.protocolResult?.headerId;
        let newProtocol = action.meta.arg.data?.protocol || {};

        let newProtocols = {
          ...state.patientProtocols,
          [state.currentPatientId]: {
            ...state.patientProtocols[state.currentPatientId],
            protocolGroups: state.patientProtocols[state.currentPatientId].protocolGroups.map(group => {

              let newGroup = {};

              if (group.id === publicId) {
                newGroup = {
                  ...group,
                  data: [
                    {
                      ...newProtocol,
                      headerId: newHeaderId,
                    },
                    ...group.data,
                  ],
                }
              } else {
                newGroup = group;
              }

              return newGroup;

            })
          }
        };
        state.patientProtocols = newProtocols;

        state.editedProtocol.headerId = newHeaderId;

        console.log({ ...state });
        console.log(state.patientProtocols);
        console.log({ ...state.patientProtocols[state.currentPatientId].protocolGroups });
        console.log(action);
        console.log(action.meta.arg.data);
        console.log(action.payload);
      })

      .addCase(sendWriteProtocol.rejected, (state, action) => {
        state.status = "idle";
        state.loading.protocolItem = false;
      })




      .addCase(sendReplaceProtocol.pending, (state) => {
        state.status = "loading";
        state.loading.protocolItem = true;
      })

      .addCase(sendReplaceProtocol.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.protocolItem = false;

        let publicId = action.meta.arg.data?.protocol?.publicId;
        let headerId = action.meta.arg.data?.headerId;
        let newHeaderId = action.payload.result?.protocolResult?.headerId;
        let newProtocol = action.meta.arg.data?.protocol || {};

        let newProtocols = {
          ...state.patientProtocols,
          [state.currentPatientId]: {
            ...state.patientProtocols[state.currentPatientId],
            protocolGroups: state.patientProtocols[state.currentPatientId].protocolGroups.map(group => {

              let newGroup = {};

              if (group.id === publicId) {

                newGroup = {
                  ...group,
                  data: group.data.map(dataItem => {

                    let newDataItem = {};

                    if (dataItem.headerId === headerId) {
                      console.log({ ...state.editedProtocol });
                      console.log({ ...newProtocol });
                      newDataItem = {
                        ...newProtocol,
                        headerId: newHeaderId,
                      }
                    } else {
                      newDataItem = dataItem;
                    }
                    return newDataItem;
                  })
                }

              } else {
                newGroup = group;
              }

              return newGroup;


            })
          }
        };
        state.patientProtocols = newProtocols;

        state.editedProtocol.headerId = newHeaderId;

        console.log({ ...state });
        console.log(state.patientProtocols);
        console.log({ ...state.patientProtocols[state.currentPatientId].protocolGroups });
        console.log(action);
        console.log(action.meta.arg.data);
        console.log(action.payload);
      })

      .addCase(sendReplaceProtocol.rejected, (state, action) => {
        state.status = "idle";
        state.loading.protocolItem = false;
      })




      ;
  },
});

export const {
  addToEventsList,
  addToSymptomsList,
  addToClarificationsList,
  addToPatientDisease,
  addToPatientEditDisease,
  addToPatientClarificationsList,
  addToPatientEditClarificationsList,
  removeSymptomById,
  removeFromEventsList,
  removeClarificationById,
  removePatientDiseaseById,
  removePatientEditDiseaseById,
  removePatientClarificationById,
  removePatientEditClarificationById,
  resetSymptomsList,
  resetSettings,
  resetPatient,
  resetPatientEdit,
  setCurrentSymptom,
  setCurrentPatientDisease,
  setResultGraphId,
  setRASPage,
  setSensorValue,
  setSetting,
  setSettings,
  setPatient,
  setProtocolMode,
  setEditedProtocol,
  setCurrentPatientId,
  setCurrentProtocolId,
  setCurrentProtocolGroupId,
  setPatientEdit,
  resetCurrentSymptom,
  resetCurrentPatientDisease,
  resetByKeys,
  updateClarificationList,
  updateHeaderId,
} = pagesSlice.actions;

export const selectPatientsList = (state) => state.pages.patientsList;
export const selectCurrentPatient = (state) => state.pages.patientsList.find(patient => patient.id === state.pages.currentPatientId);
export const selectCurrentProtocolGroup = (state) => state.pages.findedProtocols.find(protocolGroup => protocolGroup.id === state.pages.currentProtocoGrouplId);
export const selectFindedProtocols = (state) => state.pages.findedProtocols;
export const selectLoading = (state) => state.pages.loading;
export const selectSettings = (state) => state.pages.settings;
export const selectProtocolMode = (state) => state.pages.protocolMode;
export const selectProtocolPatern = (state) => state.pages.protocolPatern;
export const selectEditedProtocol = (state) => state.pages.editedProtocol;

export const selectCurrentProtocol = (state) => {

  if (state.pages.currentProtocolId === "new") {


    let patient = state.pages.patientsList?.find(patient => patient.id === state.pages.currentPatientId) || {};

    return {
      ...EMPTY_PROTOCOL,
      publicId: state.pages.currentProtocolGroupId,
      patient: {
        ...patient,
        publicId: patient.id,
      }
    };
  }
  const currentGroup = state.pages.patientProtocols[state.pages.currentPatientId]?.protocolGroups?.find(protocolGroup => protocolGroup.id === state.pages.currentProtocolGroupId) || [];
  const currentProtocol = currentGroup?.data.find(protocol => protocol.headerId === state.pages.currentProtocolId) || {};

  return currentProtocol;
}

export const selectProtocolGroupsByCurrentPatient = (state) => {
  const { currentPatientId, patientProtocols } = state.pages;

  if (currentPatientId && patientProtocols && patientProtocols[currentPatientId]) {
    return patientProtocols[currentPatientId].protocolGroups;
  }

  return [];
}




export const selectCurrentSymptom = (state) => {

  if (state.pages.editedProtocol && state.pages.editedProtocol.events && state.pages.editedProtocol.events[state.pages.currentSymptom]) {
    return state.pages.editedProtocol.events[state.pages.currentSymptom];
  }
  return initialState.currentSymptom;
}


export const selectClarificationData = (state) => state.pages.clarificationData;






export default pagesSlice.reducer;
