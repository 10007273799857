import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'features/auth/authSlice';
import alertsReducer from 'features/alerts/alertsSlice';
import pagesReducer from 'features/pages/pagesSlice';
import mainReducer from 'features/main/mainSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alerts: alertsReducer,
    pages: pagesReducer,
    main: mainReducer,
  },
});
