import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";
import { withStyles, createStyles } from '@mui/styles';
import {
    Autocomplete,
    Box,
    Typography,
    Grid,
    TextField,
} from '@mui/material';

import { debounce } from '@mui/material/utils';

import {
    // sendSearch,
    // addToSymptomsList,
    // addToClarificationsList,
    // addToPatientEditDisease,
    // addToPatientEditClarificationsList,
    // sendGetSymptomsFromText,
    addToEventsList,
    selectProtocolPatern,
} from "features/pages/pagesSlice";


const styles = () => createStyles({
    "test2": {
        // height: "30px",

        // // background: "red !important",
        // "& div":{

        //     width: "auto !important",

        //     background: "green !important",
        // }


    }

});




const MultiSearchComponent = ({ defaultValue = {}, type, resultItemProps = {}, label = "", item = {}, itemIcon = "", showLevel = false, inputColor = "", variant = "inline", sx = {}, classes }) => {
    const dispatch = useDispatch();

    const [value, setValue] = useState(defaultValue);
    const [optionsDisabled, setOptionsDisabled] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const protocolPatern = useSelector(selectProtocolPatern);


    console.log(protocolPatern);
    console.log(item);
    console.log(classes);

    const handleKeyPress = (event) => {
        if (event.code === "Enter" || event.key === "Enter" || event.keyCode === "13") {
            if (!isHighlighted && variant === "parse") {
                // dispatch(sendGetSymptomsFromText({ data: { text: inputValue } }));
                console.log("search text - " + inputValue);
                event.preventDefault();
                // event.preventDefault();
            }
        }
    }



    return <>
test
    </>;
}

export const MultiSearch = withStyles(styles, { withTheme: true })(MultiSearchComponent);
