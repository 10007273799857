import { SERVER } from './server';

const path = "/api/emcadapter/front/Authentication";
const offlineServer = "https://fakestoreapi.com";

const SERVERS = {
    prod: {
        api: "https://service.socmedica.com:9093" + path,
        auth: "https://service.socmedica.com:9093" + path,
    },
    dev: {
        api: "https://service.socmedica.dev:9093" + path,
        auth: "https://service.socmedica.dev:9093" + path,
    },
    box: {
        api: "http://ecp.box:30946" + path,
        auth: "http://ecp.box:44380" + path,
    },
};

const getUrls = () => {
    const { api: APIServer, auth: authServer } = SERVERS[SERVER];

    return {
        root: APIServer,
        auth: {
            login: authServer + "/Authorization",
            registration: authServer + "/Send",
            confirm: authServer + "/ConfirmEmail",
            getConfirm: authServer + "/GetConfirmEmail",
            recovery: authServer + "/Recovery",
            resetPass: authServer + "/ResetPassword",
            checkAuth: authServer + "/AuthCheck",
            // checkAuth2: offlineServer + "/products",
        },
    }
}

export const API_URLS = getUrls();
