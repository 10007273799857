import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Alert,
    Box,
    List,
} from "@mui/material";

import { Loader } from "UI";

import {
    selectLoading,
    selectEditedProtocol,
    // selectSymptomsList,
    // selectCurrentProtocol,
    // setEditedProtocol,
} from "features/pages/pagesSlice";

import { SymptomItem } from "features/pages"; // ServiceInfo

const SymptomsListComponent = ({ id }) => {
    const dispatch = useDispatch();

    const { serviceList: loading } = useSelector(selectLoading);
    // const symptomsList = useSelector(selectSymptomsList);
    // const protocol = useSelector(selectCurrentProtocol);
    const editedProtocol = useSelector(selectEditedProtocol);




    const symptomsList = editedProtocol.events || [];

    console.log(editedProtocol);
    return <>
        <Box sx={{
            // height: "calc(100vh - 180px)",
            width: "100%",
            overflow: "auto"
        }}>
            <Loader loading={loading} variant="replace">
                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                    }}>
                    {symptomsList.length ? symptomsList.map((item, index) => (
                        <SymptomItem item={item} index={index} key={`${index}_${item.publicId}_${Math.random()}`} />
                    )) : <Alert severity="info" sx={{ m: 5 }}>Добавьте симптомы через поисковую строку наверху</Alert>}
                </List>
            </Loader>
        </Box>
    </>;
}

export const SymptomsList = memo(SymptomsListComponent);
