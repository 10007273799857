export const isValidEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const checkErrors = (data, validationFields) => {
    let errors = {};

    console.log(data);

    validationFields.forEach(validationField => {
        const { name, required = false, type = null } = validationField;

        if (required && isEmptyString(data[name])) {
            errors[name] = "Обязательно заполните это поле";
        } else {
            if (type) {
                if (type === "password2") {
                    if (data["password"] !== data["password2"]) {
                        errors["password2"] = "Введённые пароли не совпадают";
                    }
                } else {

                    if (required || (!required && data[name])) {
                        const error = _checkByType(data[name], type);

                        if (error) {
                            errors[name] = error;
                        }
                    }

                }
            }
        }
    });

    return errors;
}

export const isEmptyString = (content) => {
    content = content + "";
    
    if (content?.length) {
        return false;
    }

    return true;
}


const _checkByType = (content, type) => {
    switch (type) {

        case "email":
            if (!isValidEmail(content)) {
                return "Введите корректный email";
            }
            break;

        case "password":
            if (content?.length < 6) {
                return "Минимум 6 символов";
            }
            break;

        case "number":
            if (!(/^\d*\.?\d*$/.test(content))) {
                return "Только цифры";
            }
            break;

        default:
            return "";
    }

}