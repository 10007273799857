export { API_URLS } from "./APIUrls";
export { levels } from "./levels";
export { tools } from "./tools";

export const DRAWER_WIDTH = 340;
export const MIN_SEARCH_TEXT = 1;
export const MAX_SEARCH_WORDS = 4;

export const OFFLINE_MODE = false;


export const ICON_LIBS = {
    "FA": {
        color: "",
        // iconsMeta: iconsMetaFA,
        fontFamily: "FontAwesome",
    },
    "GLYPH": {
        color: "",
        // iconsMeta: iconsMetaGlyph,
        fontFamily: "Glyphicons Halflings",
    },
};

export const COMPONENT_REQUEST_URL = (() => process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_API_SERVER)();

export const GRAPH_SETTINGS = {
    NODE_RADIUS: 51,
    NODE_RADIUS_SMALL: 13,
    NODE_LABEL_MARGIN: 14,
    ANCHOR_SIZE: 4,
    TOOL_TITLE_MARGIN: 4,
    MAIN_FONT_SIZE: 16,
    EDGE_COLOR: "#A6A6A6",
    NODE_STROKE_COLOR: "#3B3E3E",
    NODE_LABEL_COLOR: "#001219",
    EMPTY_COLOR: "#b2b2b2",
    LINK_LENGTH_DEFAULT: 400, // не используется
    LINK_LENGTH_DEFAULT_MIN: 250,
    LINK_LENGTH_SHORT: 100,
};

export const OPTIONS_SETTINGS = {
    EMPTY_COLOR: "#009999",
};

export const ROWS_SETTINGS = {
    DEFAULT_PAGE_SIZE: 200,
    DENSITY: 28,
};


export const GRID = 12;
export const MIN_GRID = 3;
export const SAVE_TIMEOUT = 10000;
export const COLORS = [
    "1cfc82",
    "d4867f",
    "febceb",
    "9a9a9a",
    "f4f4f4",
    "5ed3ff",
    "9ed563",
    "0082dd",
    "5db4cc",
    "00b652",
    "e6781e",
];
export const ICONS = [
    "random",
    "resize-vertical",
    "arrow-up",
    "arrow-down",
    "~",
    "resize-horizontal",
    "ok-sign",
    "resize-small",
    "fa fa-gears",
    "tasks",
    "fa fa-share-alt",
    "ban-circle",
];
