import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    Fade,
    TextField,
    InputAdornment,
    IconButton,
    // Button,
    // ButtonGroup,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useDidMountEffect } from "helpers";

import {
    Loader,
    // Dialog,
} from "UI";

import { Search, PatientsList } from "features/pages";

import {
    selectLoading,
    selectPatientsList,
    sendGetPatientsList,
    // selectResultData,
    // selectCurrentPatient,
    // resetCurrentPatient,
    // resetPatientsList,
    // sendGetDiagnostic,
} from "features/pages/pagesSlice";

import { setCurrentPage } from "features/main/mainSlice";


const PatientsComponent = () => {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    // const result = useSelector(selectResultData);

    // const currentPatient = useSelector(selectCurrentPatient);
    const { patients: loading } = useSelector(selectLoading);
    const patientsList = useSelector(selectPatientsList);

    // const [mode, setMode] = useState(result?.guid ? "view" : "analys"); // view analys

    // const handleCloseClarifications = () => {
    //     dispatch(resetCurrentPatient());
    // }

    const handleClickClearSearchText = () => {
        setSearchText("");
    }

    // const handleClickDiagnostic = () => {
    //     if (mode === "view") {
    //         dispatch(setCurrentPage("result"));
    //     } else {
    //         dispatch(sendGetDiagnostic({
    //             success: () => dispatch(setCurrentPage("result"))
    //         }));
    //     }
    // }

    // let resetDisabled = !patientsList.length;
    // let diagnosticDisabled = !patientsList.length;

    const handleChangeSearchText = (event) => {
        setSearchText(event.target.value || "");
    }

    useEffect(() => {
        dispatch(sendGetPatientsList({
            // success: () => dispatch(setCurrentPage("result"))
        }));
    }, [dispatch]);

    console.log(patientsList);
    return <Loader loading={loading}>
        <Box sx={{ height: "calc(100vh - 82px)", width: '100%' }}>
            <TextField
                id="patient-search"
                label="Поиск пациента"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={handleChangeSearchText}
                InputProps={{
                    endAdornment:
                        searchText ? <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickClearSearchText}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment> : null
                }}
            />
            <PatientsList searchText={searchText} />
        </Box>
    </Loader>;
}

export const Patients = memo(PatientsComponent);
