import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Alert,
    Box,
    Button,
    TextField,
    Divider,
    InputAdornment,
    IconButton,
    Typography,
    Tooltip,
    // Button,
    Stack,
    useTheme,
    useMediaQuery,
    // ButtonGroup,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';

// import { useDidMountEffect } from "helpers";

import {
    Loader,
    // Dialog,
} from "UI";

import { Symptoms } from "features/pages";

import {
    selectLoading,
    selectCurrentPatient,
    selectCurrentProtocol,
    selectEditedProtocol,
    selectProtocolMode,
    setEditedProtocol,
    setProtocolMode,
    sendGetProtocolGroups,
    sendReplaceProtocol,
    sendWriteProtocol,
    resetByKeys,
    updateHeaderId,
} from "features/pages/pagesSlice";

import { setCurrentPage } from "features/main/mainSlice";


const ProtocolItemComponent = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [searchText, setSearchText] = useState("");
    const { patients: loading } = useSelector(selectLoading);
    const currentPatient = useSelector(selectCurrentPatient);
    const protocol = useSelector(selectCurrentProtocol);
    const protocolMode = useSelector(selectProtocolMode);
    const editedProtocol = useSelector(selectEditedProtocol);
    const mode = useSelector(selectProtocolMode);
    const [text, setText] = useState(editedProtocol.text);

    console.log(protocolMode);
    console.log(editedProtocol);

    const handleChangeText = (event) => {
        console.log(event.target.value);
        setText(event.target.value);
    }

    const handleClickClearSearchText = () => {
        setSearchText("");
    }

    const handleChangeSearchText = (event) => {
        setSearchText(event.target.value || "");
    }

    const handleClickBack = () => {
        dispatch(setCurrentPage("protocolGroups"));
        dispatch(resetByKeys(["currentProtocolGroupId", "currentProtocolId", "protocolMode", "editedProtocol"]));
    }

    const handleClickEdit = () => {
        dispatch(setProtocolMode("edit"));
    }

    const handleClickSave = () => {
        if (editedProtocol.headerId === "new") {
            dispatch(sendWriteProtocol({
                data: {
                    protocol: {
                        ...editedProtocol,
                        headerId: null,
                        text,
                    }
                },
                success: () => {
                    dispatch(setProtocolMode("view"));
                }
            }))
        } else {
            dispatch(sendReplaceProtocol({
                data: {
                    headerId: editedProtocol.headerId,
                    protocol: {
                        ...editedProtocol,
                        text,
                    }
                },
                success: () => {
                    dispatch(setProtocolMode("view"));
                }
            }))
        }


        console.log(editedProtocol);
        // dispatch(setProtocolMode("view"));
    }

    const handleClickCancel = () => {
        dispatch(setEditedProtocol(protocol));
        dispatch(setProtocolMode("view"));
        setText(protocol.text);
    }

    useEffect(() => {
        setText(editedProtocol.text);
    }, [editedProtocol.text]);

    useEffect(() => {
        // if(mode==="edit"){
        dispatch(setEditedProtocol(protocol));
        // }
    }, [dispatch]);

    return <Loader loading={loading}>
        <Box
            sx={{
                // height: "calc(100vh - 82px)",
                width: '100%',
            }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Stack
                    sx={{
                        alignItems: "center",
                        mb: 2
                    }}
                    direction="row"
                >
                    <IconButton aria-label="back" onClick={handleClickBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">{currentPatient?.name || ""}</Typography>
                </Stack>
                <Stack
                    sx={{
                        alignItems: "center",
                        mb: 2
                    }}
                    direction="row"
                >
                    {protocolMode === "view" && editedProtocol.canEdit && <Button
                        size="small"
                        variant="text"
                        // startIcon={}
                        onClick={handleClickEdit}
                    >
                        <ModeEditIcon fontSize="small" sx={{ width: "20px", ml: "4px !important" }} /> Редактировать
                    </Button>}
                    {protocolMode === "edit" && <>
                        <Button
                            size="small"
                            variant="text"
                            // startIcon={}
                            onClick={handleClickSave}
                        >
                            <SaveIcon fontSize="small" sx={{ width: "20px", ml: "4px !important" }} /> Сохранить
                        </Button>
                        <Button
                            size="small"
                            variant="text"
                            color="error"
                            // startIcon={}
                            onClick={handleClickCancel}
                        >
                            <CancelIcon fontSize="small" sx={{ width: "20px", ml: "4px !important" }} /> Отменить
                        </Button>
                    </>}
                </Stack>
            </Stack>
            <Divider />
            <Box
                sx={{
                    fontSize: "1.2em",
                    ":first-letter": {
                        textTransform: "capitalize",
                    },
                    margin: "20px 2px 6px 6px",
                }}
            >{editedProtocol?.name || ""}</Box>


            {mode === "edit" ? <TextField
                sx={{
                    minWidth: "50%",
                    mb: 6,
                }}
                fullWidth={fullScreen}
                id="outlined-multiline-flexible"
                label="Текст"
                multiline
                minRows={2}
                value={text || ""}
                onChange={handleChangeText}
            /> : <Alert severity="info">{text || "-без текста-"}</Alert>}









            <Symptoms />
            {/* {currentProtocol?.publicId === "10081" ? <Symptoms /> : <Box>не жалобы</Box>} */}
        </Box>
    </Loader>;
}

export const ProtocolItem = memo(ProtocolItemComponent);
