import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    TextField,
    useMediaQuery,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

// import { useDidMountEffect } from "helpers";

import { Loader, Dialog } from "UI";

import {
    Search,
    SymptomsList,
    Clarifications,
} from "features/pages";

import {
    selectLoading,
    selectEditedProtocol,
    selectProtocolMode,
    // selectSymptomsList,
    // selectResultData,
    selectCurrentSymptom,
    sendGetPattern,
    resetCurrentSymptom,
    resetSymptomsList,
    // sendGetDiagnostic,
} from "features/pages/pagesSlice";
// import { setCurrentPage } from "features/main/mainSlice";


const SymptomsComponent = () => {
    const dispatch = useDispatch();
    // const result = useSelector(selectResultData);

    const currentSymptom = useSelector(selectCurrentSymptom);
    const editedProtocol = useSelector(selectEditedProtocol);
    const { protocolItem: loading } = useSelector(selectLoading);
    const mode = useSelector(selectProtocolMode);

    // console.log(editedProtocol);
    // console.log(editedProtocol.text);
    // const symptomsList = useSelector(selectSymptomsList);

    // const [mode, setMode] = useState(result?.guid ? "view" : "analys"); // view analys

    const handleCloseClarifications = () => {
        dispatch(resetCurrentSymptom());
    }

    const handleSaveClarifications = () => {
        console.log("handleSaveClarifications");
        console.log(editedProtocol);
    }

    const handleClickReset = () => {
        dispatch(resetSymptomsList());
    }

    // const handleClickDiagnostic = () => {
    //     if (mode === "view") {
    //         dispatch(setCurrentPage("result"));
    //     } else {
    //         dispatch(sendGetDiagnostic({
    //             success: () => dispatch(setCurrentPage("result"))
    //         }));
    //     }
    // }

    // let resetDisabled = !symptomsList.length;
    // let diagnosticDisabled = !symptomsList.length;

    // useDidMountEffect(() => {
    //     if (mode !== "analys") {
    //         setMode("analys");
    //     }
    // }, [mode, symptomsList]);


    useEffect(() => {
        if (mode === "edit") {
            console.log(mode);
            dispatch(sendGetPattern());
        }
    }, [dispatch, mode]);



    console.log(currentSymptom);
    console.log(currentSymptom !== null);

    console.log(editedProtocol);

    return <>
        <Loader loading={loading}>
            <Box
                sx={{
                    // height: "calc(100vh - 82px)",
                    // height: "100vh",
                    width: '100%',
                }}>
                {mode === "edit" && <Search
                    variant="parse"
                    type="Symptoms"
                    label={"Укажите симптомы (жалобы)"}
                    itemIcon={<OfflineBoltIcon sx={{ color: 'text.secondary' }} />}
                    inputColor="#3977c629"
                    resultItemProps={{
                        sx: {
                            display: "inline-block",
                            "&:first-letter": {
                                textTransform: "uppercase"
                            }
                        }
                    }}
                />}

                <SymptomsList />

                {/* <ButtonGroup
                    fullWidth
                    size="large"
                    aria-label="large button group"
                >
                    <Button
                        disabled={resetDisabled}
                        onClick={handleClickReset}
                    >Сбросить</Button>
                    <Button
                        disabled={diagnosticDisabled}
                        // onClick={handleClickDiagnostic}
                    >{"Диагностировать"}</Button>
                </ButtonGroup> */}

            </Box>

            <Dialog
                open={currentSymptom !== null}
                // title={currentSymptom.name}
                onClose={handleCloseClarifications}
                closeButton={false}
                actions={[
                    //     {
                    //     title: "Сохранить",
                    //     color: "success",
                    //     variant: "outlined",
                    //     onClick: handleSaveClarifications,
                    // },
                    {
                        title: "Закрыть",
                        // color: "",
                        variant: "outlined",
                        onClick: handleCloseClarifications,
                    }
                ]}
            >
                <Clarifications />
            </Dialog>
        </Loader>
    </>;
}

export const Symptoms = memo(SymptomsComponent);
