import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Grid,
  Stack,
  Alert,
} from '@mui/material';

import { checkErrors } from 'helpers';
import { CONFIG } from "../../constants";

import { Button } from "../Button";

import {
  selectRegistrationResult,
  selectAuthData,
  selectErrors,
  selectLoading,
  setErrors,
  updateAuthData,
  sendRegistration,
} from '../../authSlice';


export function Registration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector(selectAuthData);
  const errors = useSelector(selectErrors);
  const loading = useSelector(selectLoading);
  const registrationResult = useSelector(selectRegistrationResult);

  const handleChangeField = (event) => {

    console.log(event.target);
    const name = event.target.id || event.target.name;
    let value = typeof (event.target.value) === "string" ? event.target.value?.trim() : event.target.value;

    if (event.target.type === "checkbox") {
      dispatch(updateAuthData({
        [name]: event.target.checked,
      }));
    } else {
      dispatch(updateAuthData({
        [name]: value,
      }));
    }
  };

  const handleSubmitRegistration = async () => {
    const formErrors = checkErrors(authData, [
      { name: "email", type: "email", required: true },
      { name: "firstName", required: true },
      { name: "lastName", required: true },
      { name: "userType", required: true },
      { name: "password", type: "password", required: true },
      { name: "password2", type: "password2", required: true },
    ]);

    dispatch(setErrors(formErrors));

    if (!Object.keys(formErrors).length) {
      const request = await dispatch(sendRegistration());

      if (request.meta.requestStatus !== "rejected") {
        navigate("/confirm");
      }
    }
  };
console.log(errors);
  return (<Grid container spacing={2}>

    <Grid
      item
      xs={12}
      sm={CONFIG.registration.twoColumns ? 6 : 12}
    >
      <Stack direction="column" spacing={2}>

        <TextField
          size='small'
          error={!!errors.email}
          id="email"
          label="Email"
          variant="outlined"
          helperText={errors.email}
          value={authData.email}
          onChange={handleChangeField}
          required
        />

        <TextField
          size='small'
          error={!!errors.firstName}
          id="firstName"
          label="Имя"
          variant="outlined"
          helperText={errors.firstName}
          value={authData.firstName}
          onChange={handleChangeField}
          required
        />

        {CONFIG.registration.fields.includes("middleName") &&
          <TextField
            size='small'
            error={!!errors.middleName}
            id="middleName"
            label="Отчество"
            variant="outlined"
            helperText={errors.middleName}
            value={authData.middleName}
            onChange={handleChangeField}
          />}

        <TextField
          size='small'
          error={!!errors.lastName}
          id="lastName"
          label="Фамилия"
          variant="outlined"
          helperText={errors.lastName}
          value={authData.lastName}
          onChange={handleChangeField}
          required
        />
      </Stack>
    </Grid>

    <Grid
      item
      xs={12}
      sm={CONFIG.registration.twoColumns ? 6 : 12}
    >
      <Stack direction="column" spacing={2}>
        {CONFIG.registration.fields.includes("lpuName") && <TextField
          size='small'
          error={!!errors.lpuName}
          id="lpuName"
          label="Название учреждения"
          variant="outlined"
          helperText={errors.lpuName}
          value={authData.lpuName}
          onChange={handleChangeField}
        />}

        {CONFIG.registration.fields.includes("department") && <TextField
          size='small'
          error={!!errors.department}
          id="department"
          label="Отделение"
          variant="outlined"
          helperText={errors.department}
          value={authData.department}
          onChange={handleChangeField}
        />}


        <TextField
          size='small'
          error={!!errors.password}
          id="password"
          label="Придумайте пароль"
          variant="outlined"
          helperText={errors.password}
          value={authData.password}
          onChange={handleChangeField}
          type="password"
        />

        <TextField
          size='small'
          error={!!errors.password2}
          id="password2"
          label="Повторите пароль"
          variant="outlined"
          helperText={errors.password2}
          value={authData.password2}
          onChange={handleChangeField}
          type="password"
        />

        <FormControl fullWidth error={!!errors.userType}>
          <InputLabel id="user-type-label">Профиль</InputLabel>
          <Select
            
            labelId="user-type-label"
            required
            name="userType"
            value={authData.userType}
            label="Профиль"
            onChange={handleChangeField}
          >
            <MenuItem value={1}>Пациент</MenuItem>
            <MenuItem value={2}>Врач</MenuItem>
          </Select>
          {errors.userType && <FormHelperText>{errors.userType}</FormHelperText>}
        </FormControl>
        {registrationResult.error && <Alert severity="error">{registrationResult.error}</Alert>}
        {registrationResult.message && <Alert severity="success">{registrationResult.message}</Alert>}

      </Stack>
    </Grid>

    <Grid item xs={12}>
      <Button
        onClick={handleSubmitRegistration}
        loading={loading}
        title="Отправить запрос на регистрацию"
      />
    </Grid>
  </Grid>
  );
}