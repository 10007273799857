import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Typography,
    Button,
    Stack,
    // ButtonGroup,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';

import { useDidMountEffect } from "helpers";

import {
    Loader,
    // Dialog,
} from "UI";

import { Search, ProtocolGroupsList } from "features/pages";

import { ProtocolListItem } from "features/pages";

import {
    selectLoading,
    selectCurrentPatient,
    selectProtocolGroupsByCurrentPatient,
    resetByKeys,
    // selectResultData,
    // selectCurrentPatient,
    // resetCurrentPatient,
    // resetPatientsList,
    // sendGetDiagnostic,
} from "features/pages/pagesSlice";

import { setCurrentPage } from "features/main/mainSlice";


const ProtocolListComponent = ({ protocolGroup = [] }) => {

    const { id, name, data: protocols = [] } = protocolGroup;

    return <>
        {protocols.map((protocol, key) => <ProtocolListItem key={protocol.headerId || key} protocol={protocol} protocolGroupId={id} />)}

    </>;
}

export const ProtocolList = memo(ProtocolListComponent);
