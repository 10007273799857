import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import 'moment/locale/ru';

import {
    Box,
    // IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    Fade,
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';


import {
    Loader,
    // Dialog,
} from "UI";



import {
    selectLoading,
    selectCurrentPatient,
    selectProtocolGroupsByCurrentPatient,
    resetByKeys,
    setCurrentProtocolId,
    setCurrentProtocolGroupId,
    // selectResultData,
    // selectCurrentPatient,
    // resetCurrentPatient,
    // resetPatientsList,
    // sendGetDiagnostic,
} from "features/pages/pagesSlice";

import { setCurrentPage } from "features/main/mainSlice";


moment.locale('ru');

const borderRadius = "10px";

const OneButton = ({ onClickItem }) => {
    return <Box
        sx={{
            borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
            minWidth: "36px",
            flexDirection: "column",
        }}
    >
        <Box
            sx={{
                flex: "1 1 auto",
                borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
                height: "100%",
                backgroundColor: "#dbeaed",
                borderBottom: "1px solid rgb(241,249,247)",
                position: "relative",
                color: "#FFF",
                cursor: "pointer",
            }}
            onClick={onClickItem}
        >
            <ArrowForwardIcon sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                msTransform: "translate(-50%, -50%)",
                transform: "translate(-50%, -50%)",
                color: "#4a96a7",
            }} />
        </Box>
    </Box>;
}

const ProtocolListItemComponent = ({ protocol = {}, protocolGroupId = "" }) => {
    const dispatch = useDispatch();
    const [isIn, setIsIn] = useState(true);
    console.log(protocol);

    let date = "";
    let time = "";

    if (moment(protocol.date).isValid()) {
        date = moment(protocol.date).format('DD.MM.YYYY');
        time = moment(protocol.date).format('HH:mm');
    }

    const handleClickItem = () => {
        console.log(protocol.headerId);
        console.log(protocolGroupId);
        dispatch(setCurrentProtocolId(protocol.headerId));
        dispatch(setCurrentProtocolGroupId(protocolGroupId));
        dispatch(setCurrentPage("protocolItem"));
    }

    return <Fade
        in={isIn}
        style={{ transformOrigin: '0 0 0' }}
        timeout={400} >
        <Box>
            <Box
                onClick={() => handleClickItem()}
                sx={{
                    display: "flex",
                    width: "auto",
                    backgroundColor: "#FFF",
                    borderRadius: borderRadius,
                    minHeight: "50px",
                    mt: 2,
                    mb: 1,
                    ml: 3,
                    mr: 3,
                    flexGrow: 1,
                    cursor: "pointer",
                }}
            >
                <Box
                    sx={{
                        // p: 2,
                        // pl: 3,
                        flex: "1 1 auto",
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            pl: 3,
                            pr: 3,
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: "600",
                            fontSize: "0.9em"
                        }}
                    >
                        <Box>{date}</Box>
                        <Box>{time}</Box>
                    </Box>
                    {<Box
                        sx={{
                            pl: 3,
                            pb: 2,
                        }}
                    >
                        {protocol.text || "-без текста-"}
                    </Box>}
                </Box>

                <OneButton onClickItem={() => handleClickItem()} />

            </Box>
        </Box>
    </Fade>;
}

export const ProtocolListItem = memo(ProtocolListItemComponent);
