import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from '@mui/icons-material/Clear';
import SettingsIcon from '@mui/icons-material/Settings';

import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Fade,
} from "@mui/material";

import {
  selectProtocolMode,
  setCurrentSymptom,
  removeSymptomById,
  removeFromEventsList,
  // sendCheckParametricData,
} from "features/pages/pagesSlice";

const SymptomItemComponent = ({ item, index }) => {
  const [isIn, setIsIn] = useState(true);

  const mode = useSelector(selectProtocolMode);
  const dispatch = useDispatch();

  const handleClickRemoveSymptom = () => {
    setIsIn(false);

    setTimeout(() => {
      dispatch(removeFromEventsList(index));
    }, 300);
  }

  const handleClickClarifications = () => {
    console.log(item, index);
    // dispatch(sendCheckParametricData({ data: { id: item.id },success:()=>{} }));
    dispatch(setCurrentSymptom(index));
  }

  let characters = [];
  let localizations = [];

  console.log(item);


  const makeClarificationsArray = (clarifications, main = true, depth = 0) => {

    if (clarifications?.length) {
      clarifications.forEach((item, key) => {
        if (item) {

          if (item.type === 0) {
            if (main) {
              characters.push(item.name);
            } else {
              characters[key] = `${characters[key]} ${item.name ? "⇒ " + item.name : ""}`;
            }
          }
          if (item.type === 1) {
            if (main) {
              localizations.push(item.name);
            } else {
              localizations[key] = `${localizations[key]} ${item.name ? "⇒ " + item.name : ""}`;
            }
          }

          if (item.clarifications?.length) {
            makeClarificationsArray(item.clarifications, false, depth + 1);
          }
        }
      });
    }

  }

  makeClarificationsArray(item.clarifications);
  console.log(characters);
  
  return (<Fade
    in={isIn}
    appear={false}
    style={{ transformOrigin: '0 0 0' }}
    timeout={400} >
    <ListItem
      sx={{
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.02)",
        "&:not(:last-child)": {
          borderBottom: "1px solid lightgray",
        },
      }}
      key={index}
      secondaryAction={
        mode === "edit" ? <>
          <IconButton edge="end" aria-label="settings" onClick={handleClickClarifications}>
            <SettingsIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete" onClick={handleClickRemoveSymptom}>
            <ClearIcon />
          </IconButton>
        </> : null}

      disablePadding
    >
      <ListItemButton>
        <ListItemText
          id={"labelId"}
          primary={<Box sx={{ display: "flex" }}>
            <Box sx={{
              fontWeight: "600",
              "&:first-letter": {
                textTransform: "uppercase"
              },
            }}>{item.name || "- без названия -"}</Box>
            <Box sx={{ color: "rgba(0, 0, 0, 0.6)", ml: 2, textTransform: "lowercase" }}>{localizations.join(", ")}</Box>
          </Box>}
          secondary={characters.join(", ")}
          secondaryTypographyProps={{ sx: { textTransform: "lowercase" } }}
        />
      </ListItemButton>
    </ListItem>
  </Fade>
  );
}

export const SymptomItem = memo(SymptomItemComponent);
