import React, { memo } from "react";
import { useSelector } from "react-redux";

import { styled, lighten, darken } from "@mui/system";

import {
    Alert,
    Box,
    List,
} from "@mui/material";

import { Loader } from "UI";

import {
    selectLoading,
    selectProtocolGroupsByCurrentPatient,
    selectFindedProtocols,
} from "features/pages/pagesSlice";

import { ProtocolGroupsItem } from "features/pages"; // ServiceInfo


const ProtocolGroupsListComponent = ({ searchText }) => {
    const { serviceList: loading } = useSelector(selectLoading);

    const protocolGroups = useSelector(selectProtocolGroupsByCurrentPatient);
    const findedProtocols = useSelector(selectFindedProtocols);

    let protocolGroupsSorted = [];


    if (searchText && searchText.length > 1) {
        protocolGroupsSorted = findedProtocols.filter(item => {
            return item.name.toUpperCase().includes(searchText.toUpperCase());
        });

        protocolGroupsSorted = protocolGroupsSorted.slice(0, 100);

        if (!protocolGroupsSorted.length) {
            return <Alert severity="info" sx={{ m: 5 }}>Нет протоколов, соответствующих критериям поиска</Alert>
        }
    } else {
        protocolGroupsSorted = protocolGroups;
    }


    console.log(searchText);
    console.log(protocolGroups);
    console.log(protocolGroupsSorted);

    return <Box sx={{
        // height: "calc(100vh)",
        width: "100%",
        overflow: "auto"
    }}>
        <Loader loading={loading} variant="replace">
            <List
                sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                }}>
                {protocolGroupsSorted.length ? protocolGroupsSorted.map((protocolGroup, index) => {
                    console.log(protocolGroup);
                    return <React.Fragment key={`${protocolGroup.id}`}>
                        <ProtocolGroupsItem item={protocolGroup} />
                    </React.Fragment>
                }) : <Alert severity="info" sx={{ m: 5 }}>Нет протоколов для данного пациента</Alert>}
            </List>
        </Loader>
    </Box>;
}

export const ProtocolGroupsList = memo(ProtocolGroupsListComponent);
