import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import ClearIcon from '@mui/icons-material/Clear';
// import SettingsIcon from '@mui/icons-material/Settings';

import {
  Box,
  // IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Fade,
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  // removePatientById,
  // sendCheckParametricData,
  // setCurrentPatient,
  sendGetProtocolGroups,
  setCurrentProtocolGroupId,
  setCurrentProtocolId,
  setProtocolMode,
} from "features/pages/pagesSlice";

import {
  setCurrentPage,
} from "features/main/mainSlice";

import { ProtocolList } from "features/pages";


const borderRadius = "10px";
const backgroundColor_1 = "#f1f9f7";
const backgroundColor_2 = "#f4f4f4";
const buttonColor_1 = "#4a96a7";
const buttonColor_2 = "#808080";


const OneButton = ({ onClickAdd }) => {
  return <Box
    sx={{
      borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
      minWidth: "36px",
      flexDirection: "column",
    }}
  >
    <Box
      sx={{
        flex: "1 1 auto",
        borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
        height: "100%",
        backgroundColor: buttonColor_2,
        borderBottom: "1px solid rgb(241,249,247)",
        position: "relative",
        color: "#FFF",
        cursor: "pointer",
      }}
      onClick={onClickAdd}
    >
      <AddIcon sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        msTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }} />
    </Box>
  </Box>;
}

const TwoButtons = ({
  expanded,
  onClickExpand,
  onClickAdd,
}) => {

  console.log(expanded);
  return <Box
    sx={{
      borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
      minWidth: "36px",
      flexDirection: "column",
    }}
  >
    <Box
      sx={{
        flex: "1 1 auto",
        borderRadius: `0 ${borderRadius} 0 0`,
        height: "50%",
        backgroundColor: buttonColor_1,
        borderBottom: "1px solid rgb(241,249,247)",
        position: "relative",
        color: "#FFF",
        cursor: "pointer",
      }}
      onClick={onClickAdd}
    >
      <AddIcon sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        msTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }} />
    </Box>

    <Box
      sx={{
        flex: "1 1 auto",
        borderRadius: `0 0 ${borderRadius} 0`,
        height: "50%",
        backgroundColor: buttonColor_1,
        borderTop: "1px solid rgb(241,249,247)",
        position: "relative",
        color: "#FFF",
        cursor: "pointer",
      }}
      onClick={onClickExpand}
    >
      <ExpandMoreIcon sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transition: "all 0.3s",
        msTransform: "translate(-50%, -50%)",
        transform: `translate(-50%, -50%) ${expanded ? "rotate(180deg)" : ""}`,
      }} />
    </Box>
  </Box>;
}









const ProtocolGroupsItemComponent = ({ item }) => {
  const [isIn, setIsIn] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const { id, name, data = [] } = item;

  console.log(item);
  // let data = item.data || [];

  const dispatch = useDispatch();

  const handleClickItem = (id) => {
    console.log("ITEM ", id);
  }

  const handleClickAdd = (protocolGroupId) => {
    console.log("ADD ", id);

    
      // console.log(protocol.headerId);
      // console.log(protocolGroupId);
      dispatch(setCurrentProtocolId("new"));
      dispatch(setCurrentProtocolGroupId(protocolGroupId));
      dispatch(setCurrentPage("protocolItem"));
      
      dispatch(setProtocolMode("edit"));


  }

  const handleClickExpand = (id) => {
    setExpanded(!expanded);
  }

  // const handleClickClarifications = () => {
  //   dispatch(sendCheckParametricData({ data: { id: item.id },success:()=>{} }));
  //   dispatch(setCurrentPatient(item.id));
  // }

  const firstItem = data.length ? data[0] : null;

  return (<Fade
    in={isIn}
    // style={{ transformOrigin: '0 0 0' }}
    timeout={300}
  >
    <Box>
      <Box
        sx={!expanded && data.length > 1 ? {
          ":after": {
            display: "flex",
            content: '""',
            backgroundColor: "#eaf2f3",
            width: "95%",
            height: "10px",
            m: "-12px auto 10px auto",
            borderRadius: `0  0 ${borderRadius} ${borderRadius}`,
          }
        } : {}}>
        <Box
          onClick={() => handleClickItem(id)}
          sx={{
            display: "flex",
            width: "100%",
            backgroundColor: data.length ? backgroundColor_1 : backgroundColor_2,
            borderRadius: borderRadius,
            // minHeight: "80px",
            mt: 3,
            mb: 3,
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              p: 2,
              pl: 3,
              flex: "1 1 auto",
            }}
          >
            <Box
              sx={{
                p: 2,
                pl: 3,
                flex: "1 1 auto",
                fontWeight: "500",
                fontSize: "1.3em"
              }}
            >
              {name}
            </Box>
            <Box
              sx={{
                pl: 3,
                pb: 2,
                flex: "1 1 auto",
              }}
            >
              {firstItem && firstItem.text && !expanded && <Box
                sx={{
                  fontSize: "0.9em"
                }}
              >
                {firstItem.text}
              </Box>}
            </Box>
          </Box>

          {data.length ? <TwoButtons expanded={expanded} onClickExpand={handleClickExpand} onClickAdd={() => handleClickAdd(item.id)} /> : <OneButton onClickAdd={() => handleClickAdd(id)} />}

        </Box>
      </Box>
      <Box
        sx={data.length > 1 ? {
          display: "flex",
          backgroundColor: "#eaf2f3",
          width: "98%",
          m: "-12px auto 0 auto",
          borderRadius: `0  0 ${borderRadius} ${borderRadius}`,
          flexDirection: "column",
        } : {}}>
        {expanded && <ProtocolList protocolGroup={item} />}
      </Box>
    </Box>
  </Fade>
  );
}

export const ProtocolGroupsItem = memo(ProtocolGroupsItemComponent);
