import { API_URLS } from "constants";
import { POST } from "helpers";


export function fetchGetPatientsList({ data = {}, success = null }) {
  return POST({
    path: API_URLS.main.GetPatientsByAuthKey,
    data: {
      authkey: data.authKey
    },
    success: () => {
      success && success();
    },
  });
}

export function fetchReplaceProtocol({ data = {}, success = null }) {
  return POST({
    path: API_URLS.main.ReplaceProtocol,
    data: {
      // authkey: data.authKey,
      ...data,
    },
    success: () => {
      success && success();
    },
  });
}

export function fetchWriteProtocol({ data = {}, success = null }) {
  return POST({
    path: API_URLS.main.WriteProtocol,
    data: {
      // authkey: data.authKey,
      ...data,
    },
    success: () => {
      success && success();
    },
  });
}


export function fetchGetPattern({ data = {}, success = null }) {
  return POST({
    // path: "https://fakestoreapi.com/products",
    path: API_URLS.main.GetCreationPatternByProtocol,
    data: {
      authkey: data.authKey,
      idProtocol: data.protocolGroupId,
    },
    success: () => {
      success && success();
    },
  });
}

export function fetchGetProtocolGroups({ data = {}, success = null }) {
  return POST({
    // path: "https://fakestoreapi.com/products",
    path: API_URLS.main.GetPatientsProtocolsByAuthorAuthKey,
    data: {
      authkey: data.authKey,
      patients: [data.patientId],
    },
    success: () => {
      success && success();
    },
  });
}
