import React, { memo } from "react";
import { useSelector } from "react-redux";

import { styled, lighten, darken } from "@mui/system";

import {
    Alert,
    Box,
    List,
} from "@mui/material";

import { Loader } from "UI";

import {
    selectLoading,
    selectPatientsList,
} from "features/pages/pagesSlice";

import { PatientItem } from "features/pages"; // ServiceInfo

const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    fontWeight: "700",
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === "light"
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
    padding: 0,
});


const PatientsListComponent = ({ searchText }) => {
    const patientsList = useSelector(selectPatientsList);
    console.log(patientsList);
    let patientsListSorted = patientsList.toSorted((a, b) => -b.name.localeCompare(a.name));


    if (searchText) {
        patientsListSorted = patientsListSorted.filter(item => {
            return item.name.toUpperCase().includes(searchText.toUpperCase());
        });

        if (!patientsListSorted.length) {
            return <Alert severity="info" sx={{ m: 5 }}>Нет пациентов, соответствующих критериям поиска</Alert>
        }
    }


    const grouped = {};

    patientsListSorted.forEach((option) => {
        let firstLetter = option.name[0].toUpperCase();

        firstLetter = /[^a-zA-Zа-яА-Я]/.test(firstLetter) ? "0-9" : firstLetter;

        if (!grouped.hasOwnProperty(firstLetter)) {
            grouped[firstLetter] = [];
        }
        grouped[firstLetter].push(option);
    });

    console.log(searchText);

    return <Box sx={{
        // height: "calc(100vh)",
        width: "100%",
        overflow: "auto"
    }}>
        <List
            sx={{
                width: "100%",
                bgcolor: "background.paper",
            }}>
            {patientsList.length ? Object.keys(grouped).map((key, index) => {
                return <React.Fragment key={`${index}_${key}`}>
                    <GroupHeader>{key}</GroupHeader>
                    <GroupItems>{grouped[key].map((item) => <PatientItem item={item} key={`${index}_${item.id}`} />)}</GroupItems>
                </React.Fragment>
            }
            ) : <Alert severity="info" sx={{ m: 5 }}>Нет пациентов</Alert>}
        </List>
    </Box>;
}

export const PatientsList = memo(PatientsListComponent);
