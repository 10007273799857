import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Typography,
    // Button,
    Stack,
    // ButtonGroup,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';

// import { useDidMountEffect } from "helpers";

import {
    Loader,
    // Dialog,
} from "UI";

import { ProtocolGroupsList } from "features/pages";

import {
    selectLoading,
    selectCurrentPatient,
    resetByKeys,
} from "features/pages/pagesSlice";

import { setCurrentPage } from "features/main/mainSlice";


const ProtocolGroupsComponent = () => {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const { patients: loading } = useSelector(selectLoading);
    const currentPatient = useSelector(selectCurrentPatient);

    const handleClickClearSearchText = () => {
        setSearchText("");
    }


    const handleChangeSearchText = (event) => {
        setSearchText(event.target.value || "");
    }

    const handleClickBack = (event) => {
        dispatch(setCurrentPage("patients"));
        dispatch(resetByKeys(["currentPatientId", "protocolGroups"]));
    }

    // useEffect(() => {
    //     dispatch(sendGetPatientsList({
    //         // success: () => dispatch(setCurrentPage("result"))
    //     }));
    // }, [dispatch]);

    return <Loader loading={loading}>
        <Box sx={{ height: "calc(100vh - 82px)", width: '100%' }}>
            <Stack
                sx={{
                    alignItems: "center",
                    mb: 2
                }}
                direction="row"
            >
                <IconButton aria-label="back" onClick={handleClickBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">{currentPatient?.name || ""}</Typography>
            </Stack>

            <TextField
                id="patient-search"
                label="Протокол ЭМК"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={handleChangeSearchText}
                InputProps={{
                    endAdornment:
                        searchText ? <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickClearSearchText}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment> : null
                }}
            />
            <ProtocolGroupsList searchText={searchText} />
        </Box>
    </Loader>;
}

export const ProtocolGroups = memo(ProtocolGroupsComponent);
