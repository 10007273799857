import React, { memo, useState, useEffect, } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ClickAwayListener } from '@mui/base';

import {
    Box,
    ButtonGroup,
    Button,
    Divider,
    Input,
    InputLabel,
    TextField,
    Menu,
    MenuItem,
    FormControl,
    Typography,
    Popover,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Stack,
    Select,
    IconButton,
    Tooltip,
} from '@mui/material';

import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CloseIcon from '@mui/icons-material/Close';
import ArrawIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    Search,
    MultiSearch,
    ClarificationsList,
} from "features/pages";

import {
    Loader,
} from "UI";

import {
    selectCurrentSymptom,
    selectEditedProtocol,
    selectLoading,
    selectClarificationData,
    selectProtocolPatern,
    updateClarificationList,
} from "features/pages/pagesSlice";

const EMPTY_EVENT = {
    parents: null, // только для поиска (paternElements)
    publicId: "new",
    name: "",
    value: null,
    satelliteData: null,
    clarifications: [],
    libId: 0,
    type: 0,
};

const ITEM_HEIGHT = 48;
const MIN_SEARCH_TEXT = 1;

const colors = {
    0: "rgba(225, 114, 224, 0.3)", // Details #e172e0
    1: "rgba(245, 183, 38, 0.3)", // Localization #f5b726
};

const ClarificationsRowComponent = ({ clarification, paternElements: paternElementsOriginal, currentSymptom, rowIndex, onRemoveRow }) => {
    const dispatch = useDispatch();

    const editedProtocol = useSelector(selectEditedProtocol);
    const [anchorEl, setAnchorEl] = React.useState({});
    const [inputValue, setInputValue] = useState({});
    const [options, setOptions] = useState({});
    const [valueArray, setValueArray] = useState({});
    const [paternElements, setPaternElements] = useState(paternElementsOriginal);



    console.log(Object.keys(paternElementsOriginal).length);
    console.log(currentSymptom);
    console.log(anchorEl);
    console.log(clarification);
    console.log(paternElements);


    const presetValueArray = (valueArray) => {
        console.log("valueArray", valueArray);

        let mixedArr = null;
        Object.keys(valueArray).reverse().forEach(valueIndex => {
            console.log(valueIndex);
            if (!valueArray[valueIndex].new) {
                if (mixedArr) {
                    mixedArr = [{
                        ...valueArray[valueIndex],
                        clarifications: mixedArr,
                    }];
                } else {
                    mixedArr = [valueArray[valueIndex]];
                }
            }
        });

        console.log("mixedArr", mixedArr);
        setValueArray(valueArray);
        dispatch(updateClarificationList({ mixedArr: mixedArr[0], rowIndex }));
    };

    const handleClick = (event, index) => {
        setAnchorEl({
            ...anchorEl,
            [index]: event.currentTarget,
        });
    };

    const handleClose = (event, index) => {
        setAnchorEl({
            ...anchorEl,
            [index]: null,
        });
        console.group("handleClose");
        console.log(valueArray);
        console.log(inputValue);
        console.log(currentSymptom);
        console.groupEnd();


        // если поле не пустое, вписываем текущее значение
        if (inputValue[index]) {
            setInputValue({
                ...inputValue,
                [index]: valueArray[index].name,
            });
        }

    };

    const handleRemoveRow = (event, rowIndex) => {
        setInputValue({});
        setValueArray({});
        dispatch(updateClarificationList({ mixedArr: null, rowIndex }));
        onRemoveRow(rowIndex);
    };

    const handleRemoveValue = (event, index) => {
        console.log(event, index);

        let newInputValue = {};
        let newValueArray = {};

        newInputValue = {
            ...inputValue,
            [index]: "",
        };
        newValueArray = {
            ...valueArray,
            [index]: {
                ...valueArray[index],
                publicId: `NEW_${rowIndex}_${index}`,
                name: "",
                new: true,
            },
        };

        Object.keys(inputValue).forEach(valueKey => {
            if (valueKey > index) {
                delete newInputValue[valueKey];
                delete newValueArray[valueKey];
            }
        });

        setInputValue(newInputValue);
        presetValueArray(newValueArray);
    };


    const hasChild = (paternElement) => {
        console.log(paternElement);

        const parent = Object.keys(paternElements).find(elementId => {
            return paternElements[elementId].parents?.includes(paternElement.publicId);

        });
        console.log(parent);

        return !!parent;
    }


    const handleChangeSelectItem = (event, index) => {
        handleClickSearchItem(event.target.value, index);
    }

    const handleClickSearchItem = (option, index) => {
        console.log(option);
        console.log(paternElements[option]);

        let name = paternElements[option] ? paternElements[option].name : "";
        let type = paternElements[option] ? paternElements[option].type : null;

        let newInputValue = {
            ...inputValue,
            [index]: name,
            publicId: option,
        };
        let newValueArray = {
            ...valueArray,
            [index]: {
                ...paternElements[option],
                publicId: option,
            }
        };


        Object.keys(newInputValue).forEach(valueKey => {
            if (valueKey > index) {
                delete newInputValue[valueKey];
                delete newValueArray[valueKey];
            }
        });

        // если есть дети, добавляем пустое поле
        if (hasChild(paternElements[option])) {
            newInputValue[index + 1] = "";

            newValueArray[index + 1] = {
                ...EMPTY_EVENT,
                type: type,
                publicId: `newBySearch_${option}_${index}`,
                // parents: [currentSymptom.publicId]
            };
        }

        setInputValue(newInputValue);
        presetValueArray(newValueArray);

    };

    const handleInput = (event, index) => {
        event.target.setAttribute('size', event.target.value.length);

        const newInputValue = event.target.value;

        setInputValue({
            ...inputValue,
            [index]: newInputValue,
        });

        if (newInputValue === '' || newInputValue.length <= MIN_SEARCH_TEXT) {
            // setOptions(value ? [value] : []);
            return undefined;
        }

        const wordsCount = (newInputValue.match(/\S+/g) || []).length;

        console.log(wordsCount);
        console.log(paternElements);


        let results;
        let parent = index ? valueArray[index - 1] : currentSymptom;
        let type = valueArray[0] ? valueArray[0].type : null;
        console.log(type);
        console.log("parent", parent);

        results = Object.keys(paternElements).filter(id => {
            return paternElements[id].name.toUpperCase().includes(newInputValue.toUpperCase()) && paternElements[id].parents?.includes(parent.publicId) && paternElements[id].type === type;
        });

        console.log("results", results);

        let newOptions = [];

        if (results) {
            newOptions = [...newOptions, ...results];
        }

        console.log(newOptions);
        setOptions({
            ...options,
            [index]: newOptions
        });

    };

    /**
     * @description Клик за пределы поля и выпадающего списка, нужно закрыть список и проверить, можно ли оставить в поле выбранное значение, иначе очистить
     * @param {*} event 
     */
    const handleClickAway = (event) => {
        console.log("handleClickAway");
    }
    const handleChange = (event) => {
        console.log("CHANGE", event.target.value);
        event.target.setAttribute('size', event.target.value.length);
    }


    useEffect(() => {
        console.log("useEffect", clarification)
        const makeClarificationsArray = (clarifications, main = true, depth = 0, characters = {}) => {
            console.log(clarifications);
            console.log(characters);

            if (clarifications?.length) {
                clarifications.forEach((item, key) => {
                    if (item.type === "Details" || item.type !== "Details") {

                        if (item.clarifications?.length) {
                            characters[depth] = {
                                ...item,
                                clarifications: [],
                            };
                            makeClarificationsArray(item.clarifications, false, depth + 1, characters);
                        } else {
                            characters[depth] = item;
                        }
                    }
                });
            }
            return characters;
        }

        const characters = makeClarificationsArray([clarification]);

        setValueArray(characters);
        console.log(characters);

        Object.keys(characters).forEach((character, index) => {
            setInputValue((inputValueState) => ({
                ...inputValueState,
                [index]: characters[character].name,
            }));
        });

    }, []);



    useEffect(() => {
        /**
         * Добавляем в поиск сохранённые элементы, если их там нет
        */
        let parentId = currentSymptom.publicId;
        let newPaternElements = JSON.parse(JSON.stringify(paternElements));
        console.log(Object.keys(newPaternElements).length);
        Object.keys(valueArray).forEach(valueKey => {
            let value = valueArray[valueKey];
            // в патернах такой элемент уже есть
            if (newPaternElements.hasOwnProperty(value.publicId)) {
                let elementParents = newPaternElements[value.publicId].parents || [];

                // нет информации о паренте, добавляем
                if (!elementParents.includes(parentId)) {
                    newPaternElements[value.publicId] = {
                        ...value,
                        parents: [...elementParents, parentId],
                    }
                }

                // в патернах элемента нет, добавляем
            } else {
                newPaternElements[value.publicId] = {
                    ...value,
                    parents: [parentId],
                }
            }


            // Собираем список для селекта, если не первое поле, игнорировать type
            ///////////////////////////

            let results;

            results = Object.keys(newPaternElements).filter(id => {
                return newPaternElements[id].parents?.includes(parentId) && (newPaternElements[id].type === type || valueKey !== "0");
            });

            console.log("results", results);
            let newOptions = [];

            if (results) {
                newOptions = [...newOptions, ...results];
            }

            console.log(newOptions);
            setOptions(ttt => ({
                ...ttt,
                [valueKey]: newOptions
            }));


            //////////////////////////////


            parentId = value.publicId;
        });

        console.log(Object.keys(newPaternElements).length);
        setPaternElements(newPaternElements);

        //  <==
    }, [valueArray]);

    console.log("options", options);
    console.log("inputValue", inputValue);
    console.log("valueArray", valueArray);
    console.log("clarification", clarification);

    let type = valueArray[0] ? valueArray[0].type : null;
    console.log(type);
    // type = !type && clarification ? clarification.type : null;
    let rowColor = type !== null ? colors[type] : "";

    return <>
        <Box
            sx={{
                backgroundColor: rowColor,
                justifyContent: "space-between",
                display: "flex",
                my: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                }}>
                {Object.keys(valueArray).map((value, index) => <Box
                    key={index}
                >
                    <ClickAwayListener onClickAway={(event) => handleClose(event, index)}>
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                }}>
                                {index ? <ArrawIcon
                                    fontSize="small"
                                    color="info"
                                    sx={{ mr: 2, mt: 0, mb: 1 }}
                                /> : null}

                                <Input
                                    onClick={(event) => handleClick(event, index)}
                                    value={inputValue[index]}
                                    placeholder={"начните ввод..."}
                                    sx={{
                                        // width: Math.floor(100/Object.keys(valueArray).length) + "%",
                                        minWidth: "140px",
                                    }}
                                    size="small"
                                    onInput={(event) => handleInput(event, index)}
                                    endAdornment={<Tooltip title="Очистить">
                                        <IconButton
                                            size="small"
                                            onClick={(event) => handleRemoveValue(event, index)}
                                        >
                                            <CloseIcon sx={{
                                                fontSize: "1em"
                                            }} />
                                        </IconButton>
                                    </Tooltip>}
                                />
                            </Box>
                            <Menu
                                id={"id"}
                                open={Boolean(anchorEl[index])}
                                anchorEl={anchorEl[index]}
                                anchorPosition={
                                    { left: 0, top: 0 }
                                }
                                onClose={(event) => handleClose(event, index)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                slotProps={{
                                    paper: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '30ch',
                                        },
                                    }
                                }}

                                disablePortal={true}
                                disableAutoFocus={true}
                            >
                                <List
                                    dense={true}
                                    component="nav"
                                >
                                    {options[index] && options[index].length ? options[index].map(option =>
                                        paternElements[option].name ? <ListItemButton
                                            onClick={() => handleClickSearchItem(option, index)}
                                            key={option}
                                        >
                                            <ListItemText
                                                primary={paternElements[option].name}
                                            />
                                        </ListItemButton> : null
                                    ) : "нет результатов"}
                                </List>
                            </Menu>
                        </>
                    </ClickAwayListener>
                </Box>
                )}
            </Box>
            <Box
                sx={{
                    // backgroundColor: "green"
                    px: 2
                }}
            >
                <Tooltip title="Удалить">
                    <IconButton
                        size="small"
                        onClick={(event) => handleRemoveRow(event, rowIndex)}
                    >
                        <DeleteIcon
                            color="action"
                            sx={{
                                // fontSize: "1em"
                            }} />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    </>;

}



const ClarificationsRow = memo(ClarificationsRowComponent);




/**
 * ==========================================
 * =================================
 * ==========================================
 */











const ClarificationsComponent = () => {
    const { clarifications: loading } = useSelector(selectLoading);
    let currentSymptomOriginal = useSelector(selectCurrentSymptom);
    // const clarificationData = useSelector(selectClarificationData);

    const [currentSymptom, setCurrentSymptom] = useState(currentSymptomOriginal);
    const [hasLocalizations, setHasLocalizations] = useState(false);
    const [hasCharacters, setHasCharacters] = useState(false);

    const protocolPatern = useSelector(selectProtocolPatern);
    console.log(currentSymptom);


    const handleAddRow = (event) => {
        console.log(event.target);
        console.log(event.target.name);

        let newClarification = {
            ...EMPTY_EVENT,
            type: +event.target.name,
            publicId: `new_${currentSymptom.clarifications.length + 1}`,
            parents: [currentSymptom.publicId]
        }

        setCurrentSymptom({
            ...currentSymptom,
            clarifications: [
                ...currentSymptom.clarifications,
                newClarification
            ],
        });

    }


    const handleRemoveRow = (rowIndex) => {
        console.log(rowIndex);

        let newClarification = [...currentSymptom.clarifications];
        newClarification.splice(rowIndex, 1);


        setCurrentSymptom({
            ...currentSymptom,
            clarifications: newClarification,
        });
    }

    useEffect(() => {

        let L = false;
        let CH = false;

        for (const elementId in protocolPatern.elements) {

            if (protocolPatern.elements[elementId].parents?.includes(currentSymptom.publicId)) {

                if (protocolPatern.elements[elementId].type === 1) {
                    L = true;
                }

                if (protocolPatern.elements[elementId].type === 0) {
                    CH = true;
                }
            }

            if (L && CH) {
                break;
            }

            console.log(L, CH);
        }
        L && setHasLocalizations(true);
        CH && setHasCharacters(true);

    }, []);


    console.log(hasLocalizations, hasCharacters);

    return <>
        <Box sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between"
        }}
        >
            <Box sx={{
                fontWeight: 600,
                "&:first-letter": {
                    textTransform: "uppercase",
                }
            }}
                component={"span"}
            >{currentSymptom?.name}</Box>

            <ButtonGroup
                variant="text"
                size="small"
                sx={{ height: "23px" }}
            >
                {hasCharacters && <Button
                    name="0"
                    onClick={handleAddRow}
                    sx={{
                        color: "#222",
                        backgroundColor: colors[0],
                    }}
                >Добавить характер</Button>}

                {hasLocalizations && <Button
                    name="1"
                    onClick={handleAddRow}
                    sx={{
                        color: "#222",
                        backgroundColor: colors[1],
                    }}
                >Добавить локализацию</Button>}
            </ButtonGroup>
        </Box>


        <Loader loading={loading}>

            <Box>
                {currentSymptom && currentSymptom.clarifications && currentSymptom.clarifications.length ? currentSymptom.clarifications.map((clarification, rowIndex) => {
                    return <ClarificationsRow
                        key={clarification.publicId}
                        clarification={clarification}
                        paternElements={protocolPatern.elements}
                        currentSymptom={currentSymptom}
                        rowIndex={rowIndex}
                        onRemoveRow={handleRemoveRow}
                    // onInputChange={(_, newInputValue) => {

                    //     const regex = /(\r\n|\r|\n)/gm;
                    //     const checkOptionsDisabled = regex.test(newInputValue);

                    //     if (checkOptionsDisabled !== optionsDisabled) {
                    //         setOptionsDisabled(checkOptionsDisabled);
                    //     }

                    //     setInputValue(newInputValue);
                    // }}
                    />;
                }) : <div></div>}
            </Box>

        </Loader>
    </>;
}

export const Clarifications = memo(ClarificationsComponent);
