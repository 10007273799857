import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Alert } from '@mui/material';

import { Loader } from "UI";

import {
    Patients,
    ProtocolGroups,
    ProtocolItem,
} from "features/pages";
// import { selectCurrentPage } from "features/pages/pagesSlice";
import { selectCurrentPage } from "features/main/mainSlice";

const ContentMemedComponent = () => {
    const currentPage = useSelector(selectCurrentPage);

    let ContentComponent = null;
    // let title = "";

    console.log(currentPage);
    switch (currentPage) {

        case "patients":
            ContentComponent = Patients;
            // title = TXT.symptoms;
            break;

        case "protocolGroups":
            ContentComponent = ProtocolGroups;
            // title = TXT.symptoms;
            break;

        case "protocolItem":
            ContentComponent = ProtocolItem;
            // title = TXT.symptoms;
            break;


        default:
            ContentComponent = <Alert severity="info">Загрузка [ unknown module {currentPage}].</Alert>;
            break;
    }

    return <>

        {/* <Typography variant="h6" sx={{ ml: "1%" }}>{title}</Typography> */}
        <ContentComponent />


    </>;
}

export const Content = memo(ContentMemedComponent);
