import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { styled } from '@mui/material/styles';

import { DRAWER_WIDTH, OFFLINE_MODE } from "constants";
import { appStorage } from "helpers";

import {
    Box,
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';

import {
    selectDrawerOpen,
    setDrawerOpen,
    setCurrentPage,
    selectCurrentPage,
} from "features/main/mainSlice";

const drawerWidth = DRAWER_WIDTH;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const Bar = () => {
    const open = useSelector(selectDrawerOpen);
    const currentPage = useSelector(selectCurrentPage);
    const dispatch = useDispatch();

    const isOffline = appStorage.getItem("isOffline");

    // const authData = useSelector(selectAuthData);
    // let displayName = (authData.firstName || authData.lastName || authData.email || "User").toUpperCase(); // Этих данных нет.
    // let displayName = "User";

    const handleDrawerOpen = () => dispatch(setDrawerOpen(true));
    // const handleDrawerClose = () => dispatch(setDrawerOpen(false));

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleLogout = () => {
        appStorage.removeItem("authKey");
        appStorage.removeItem("key");
        window.location = "/";
    }

    // const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    // };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClickPatient = () => {
        dispatch(setCurrentPage("patient"));
    };

    const handleClickOffline = () => {
        appStorage.removeItem("isOffline");
        window.location = "/";
    };

    const handleClickMenuItem = (code) => {
        if (code === "logout") {
            handleLogout();
        } else {
            dispatch(setCurrentPage(code));
            handleCloseUserMenu();
        }
    };

    const menuData = [
        // {
        //     title: "Кошелёк",
        //     code: "wallet",
        //     // disabled: true,
        //     icon: <WalletIcon fontSize="small" />,
        // },
        // {
        //     title: "Медицинская карта",
        //     code: "x_med",
        //     disabled: true,
        //     icon: <MedicalInformationIcon fontSize="small" />,
        // },
        // {
        //     title: "Мои врачи",
        //     code: "x_doc",
        //     disabled: true,
        //     icon: <MedicalServicesIcon fontSize="small" />,
        // },
        // {
        //     title: TXT.patientList,
        //     code: "patientList",
        //     icon: <AssignmentIndIcon fontSize="small" />,
        // },
        // {
        //     title: TXT.friendList,
        //     code: "friendList",
        //     icon: <GroupIcon fontSize="small" />,
        // },
        // {
        //     title: TXT.symptoms,
        //     code: "symptoms",
        //     icon: <DnsIcon fontSize="small" />,
        // },
        // {
        //     title: "Мои друзья",
        //     code: "x_fri",
        //     disabled: true,
        //     icon: <GroupIcon fontSize="small" />,
        // },
        // {
        //     title: TXT.inviteColleague,
        //     code: "referral",
        //     icon: <QrCodeIcon fontSize="small" />,
        // },
        {
            title: "Выход",
            code: "logout",
            icon: <LogoutIcon fontSize="small" />,
        },
    ];


    return <AppBar
        position="fixed"
        open={open}
        sx={{
            background: "linear-gradient(90deg, #2e6da6 30%, #071f2b 70%)",
        }}
    >
        <Toolbar variant="dense">
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
                <MenuIcon />
            </IconButton>
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ marginRight: 4, flexGrow: 1 }}
            >
                Электронная медицинская карта
            </Typography>

            {/* <Menu /> */}

            <Box sx={{ flexGrow: 0 }}>
                {/* <Box
                    onClick={handleClickPatient}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer"
                    }}
                >Пациент не выбран <PersonIcon fontSize="large" />
                </Box> */}
                {OFFLINE_MODE && isOffline && <Tooltip
                    title="Нажмите для попытки соединения (страница будет обновлена)"
                >
                    <Box
                        onClick={handleClickOffline}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "gray"
                        }}>
                        Offline <PortableWifiOffIcon fontSize="medium" />
                    </Box>
                </Tooltip>}

                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >

                    {menuData.map(menuItem => <MenuItem
                        disabled={!!menuItem.disabled}
                        selected={menuItem.code === currentPage}
                        key={menuItem.code}
                        onClick={() => handleClickMenuItem(menuItem.code)}
                    >
                        <ListItemIcon>
                            {menuItem.icon}
                        </ListItemIcon>
                        <ListItemText>{menuItem.title}</ListItemText>
                    </MenuItem>)}

                </Menu>
            </Box>
        </Toolbar>
    </AppBar>
}
