import { SERVER } from './server';

const path = "/api/emcadapter/front";

const SERVERS = {
    prod: {
        api: "https://service.socmedica.com:9093" + path,
        // auth: "https://service.socmedica.com:9093" + path,
    },
    dev: {
        api: "https://service.socmedica.dev:9093" + path,
    },
    box: {
        api: "http://ecp.box:30946" + path,
    },
};


function getUrls() {
    const { api: APIServer } = SERVERS[SERVER];

    return {
        root: APIServer,
        main: {
            GetPatientsByAuthKey: APIServer + "/ReadInterface/GetPatientsByAuthKey",
            GetPatientsProtocolsByAuthorAuthKey: APIServer + "/ReadInterface/GetPatientsProtocolsByAuthorAuthKey",
            GetCreationPatternByProtocol: APIServer + "/ReadInterface/GetCreationPatternByProtocol",
            GetProtocolsGroups: APIServer + "/TechnicalInterface/GetProtocolsGroups",

            WriteProtocol: APIServer + "/WriteInterface/WriteProtocol",
            ReplaceProtocol: APIServer + "/WriteInterface/ReplaceProtocol",

            GetDefaultProtocolDatas: APIServer + "/TechnicalInterface/GetDefaultProtocolDatas",
        },
    }
}

export const API_URLS = getUrls();