import axios from 'axios';
import { toast } from "react-toastify";

import { appStorage } from "./AppStorage";
import { OFFLINE_MODE } from "../constants";

export { appStorage } from "./AppStorage";
export { checkErrors, isValidEmail, isEmptyString } from "./validation";
export { useCurrentPath, useDidMountEffect } from "./hooks";


export { getIcon } from "./icons";

// HASH
export function cyrb53(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export function randomFromArray(items) {
    return items[items.length * Math.random() | 0];
}


export function fixColor(color) {
    if (color && color[0] !== "#") {
        color = `#${color}`;
    }

    return color;
}
export function setGraph(graph) {
    window.UMKB_graph = graph;
}

export function getGraph() {
    return window.UMKB_graph;
}

export function updateGraph(action) {
    let state = getGraph();



    console.log(state);
    let updatedGraph = JSON.parse(JSON.stringify(state));
    // let updatedGraph: any = { ...state };
    let nodes = action.graph.nodes || [];
    let edges = action.graph.edges || [];

    nodes.forEach(actionNode => {
        let nodeIndex = updatedGraph.nodes.findIndex((stateNode) => actionNode.id === stateNode.id);

        if (nodeIndex !== -1) {
            console.log(" NOT PUSH");
            let tmpData = { ...updatedGraph.nodes[nodeIndex].data };

            updatedGraph.nodes[nodeIndex] = {
                ...updatedGraph.nodes[nodeIndex],
                ...actionNode,
                data: {
                    ...tmpData,
                    ...actionNode.data,
                }
            };
        } else {
            console.log("PUSH");
            updatedGraph.nodes.push(actionNode);
        }
    });

    edges.forEach(actionEdge => {
        console.log("🚀 ~ file: reducers.ts ~ line 431 ~ graph ~ action.graph.edges", action.graph.edges)
        console.log("🚀 ~ file: reducers.ts ~ line 431 ~ graph ~ updatedGraph.edges", updatedGraph.edges)



        let edgeIndex = updatedGraph.edges.findIndex((stateEdge) => actionEdge.id === stateEdge.id);
        console.log("🚀 ~ file: reducers.ts ~ line 437 ~ graph ~ edgeIndex", edgeIndex)

        if (edgeIndex !== -1) {
            let tmpData = { ...updatedGraph.edges[edgeIndex].data };

            updatedGraph.edges[edgeIndex] = {
                ...updatedGraph.edges[edgeIndex],
                ...actionEdge,
                data: {
                    ...tmpData,
                    ...actionEdge.data,
                }
            };
        } else {
            updatedGraph.edges.push(actionEdge);
        }
    });


    console.log(updatedGraph);
    setGraph(updatedGraph);




}





export const fireToast = (alert) => {
    if (!alert.message) {
        return;
    }

    let typedToast = null;

    if (alert.level === "error") {
        typedToast = toast.error;
    } else {
        typedToast = toast.info;
    }

    typedToast(alert.message, {
        toastId: alert.message
    });
}

export const serialize = (obj, prefix) => {
    let str = [];
    let p;

    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix : p;
            let v = obj[p]; // prefix + "[" + p + "]"
            str.push(v !== null && typeof v === "object" ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }

    if (str.length) {
        return '?' + str.join("&").replace('?', '');
    }

    return '';
};

export const isAlert = (alert) => {
    return alert.message && alert.code;
}

export const FETCH = ({ params, data, success, fail, always, method, path, headers, responseType, isLocal }) => {
    const requestData = data;
    const isOffline = appStorage.getItem("isOffline");
    let requestHashKey = "";
    let OFFLINE_DATA = {};

    /**
     * Если в режиме оффлайн, создаём хэш под которым сохраним ответ и изменяем запрос на локальный, который точно будет успешным, чтобы вернуть корректный объект axios
     */
    if (OFFLINE_MODE && isOffline) {
        requestHashKey = cyrb53(JSON.stringify(requestData) + path) + "";
        path = "robots.txt";
        method = "GET";
        isLocal = true;

        // OFFLINE_DATA[requestHashKey] = data;
        // return Promise.resolve({ data: OFFLINE_DATA[requestHashKey] || {} });
    }


    return axios({
        method,
        mode: 'no-cors',
        // withCredentials: process.env.NODE_ENV !== 'production',
        withCredentials: false,
        url: CREATE_API_URL({ path, isLocal }),
        params,
        data,
        headers: headers || { 'Content-Type': 'application/json;charset=UTF-8' },
        responseType,
    })
        .then(response => {
            let data = response.data;
            let error = null;
            let alerts = [{ code: "200", message: "" }];

            if (OFFLINE_MODE) {
                OFFLINE_DATA = appStorage.getItem("OFFLINE_DATA") || {};

                if (isOffline) {
                    if (!OFFLINE_DATA[requestHashKey]) {
                        fireToast({ code: "400", message: "Нет локальных данных по данному запросу. Необходимо перейти в режим онлайн." });
                    }
                    success && success(OFFLINE_DATA[requestHashKey]);
                    always && always();
                    return OFFLINE_DATA[requestHashKey] || {};

                } else {
                    OFFLINE_DATA[requestHashKey] = data;
                    appStorage.setItem("OFFLINE_DATA", OFFLINE_DATA);
                }
            }

            // Дальнейшие проверки вариантов алертов убрать, когда бэк научится нормальные сообщения присылать (скорее всего, никогда)

            // Пришёл один алерт под ключём alert
            if (data.alert && data.alert.message) {
                alerts = [data.alert];
            }

            // Пришёл массив с алертами (Как должно быть). На всякий случай проверяем по первому элементу, действительно ли это алерты
            if (data.alerts && Array.isArray(data.alerts) && data.alerts.length && isAlert(data.alerts[0])) {
                alerts = data.alerts;
            }

            // Пришёл массив алертов в корне ответа (без ключей alert или alerts)
            if (Array.isArray(data) && data[0] && isAlert(data[0])) {
                alerts = [data[0]];
            }

            // Пришёл объект алерта в корне
            if (!Array.isArray(data) && isAlert(data)) {
                alerts = [data];
            }

            const firstAlert = alerts[0];

            if (+firstAlert.code !== 200 && firstAlert.message) {
                error = firstAlert.message;
            }

            if (firstAlert.level === "warning" && firstAlert.message) {
                error = firstAlert.message;
            }


            if (firstAlert.code === "403" || firstAlert.code === "401") {
                const storeAuthkey = appStorage.getItem("authKey");
                const storeKey = appStorage.getItem("key");

                if (storeAuthkey || storeKey) {
                    appStorage.setItem("authKey", "");
                    appStorage.setItem("key", "");
                    window.location = "/";
                }
            }

            if (data.error) {
                if (data.error === 'auth' || data.error[0] === 'auth') {
                    error = 'Ошибка ключа авторизации. Пожалуйста, выполните вход в приложение.';
                }
                error = data.error;
            }

            if (error) {
                console.log("error", error);
                return Promise.reject({ message: error, level: "error" });
            }

            fireToast(alerts[0]);
            success && success(data);
            always && always();
            return data;

        })
        .catch(error => {

            if (!error.response) {
                appStorage.setItem("isOffline", true);
            }

            const alerts = error.message ? [{ message: error.message, level: "error" }] : [{ message: 'NETWORK_ERROR', level: "error" }];
            fireToast(alerts[0]);
            fail && fail(alerts);
            always && always();

            return Promise.reject(alerts);
        });
};

export const CREATE_API_URL = ({ path, isLocal }) => {
    // if (process.env.REACT_APP_IS_LOCAL === true) {
    //     const pathArray = path.split('?');
    //     return `/proxy/${pathArray[0]}.json`;
    // }

    // if (process.env.NODE_ENV === 'production') {
    //     return `/${path}`;
    // }

    if (path.includes('http') || isLocal) {
        return path;
    }

    return `${process.env.REACT_APP_API_SERVER}/${path}`;
};

export const GET = params => FETCH({ ...params, method: 'get', path: `${params.path}${serialize(params.data)}` });
export const DELETE = params => FETCH({ ...params, method: 'delete' });
export const POST = params => FETCH({ ...params, method: 'post' });
export const PUT = params => FETCH({ ...params, method: 'put' });

export const FILE = ({ data, success, fail, always, path, onUploadProgress }) => {
    const cancelTokenSource = axios.CancelToken.source();
    const data2 = new FormData();

    Object.keys(data).forEach(key => {
        data2.append(key, data[key]);
    });

    const request = axios({
        method: 'POST',
        withCredentials: !(process.env.NODE_ENV === 'production'),
        url: CREATE_API_URL({ path }),
        data: data2,
        cancelToken: cancelTokenSource.token,
        onUploadProgress,
    })
        .then(response => {
            if (response.status !== 200 || response.data.errors) {
                throw Error;
            }
            success && success(response.data);
            always && always();
        })
        .catch(error => {
            let alerts = (error.response && error.response.data.errors) || [{ message: error.toString() }];

            fail && fail(alerts);
            always && always();
        });
    request.cancelRequest = cancelTokenSource.cancel;

    return request;
};

export const isObject = (obj) => {
    if (obj === null) { return false; }
    if (Array.isArray(obj)) { return false; }
    return ((typeof obj === 'function') || (typeof obj === 'object'));
}

export const getGroupColor = (value) => {
    const colorPallette = [
        "#007b2b",
        "#cccccc", //"#b0d512",
        "#54C8FF",
        "#fff42b",
        "#ffca2f",
        "#fc7301",
        "#f42a03",
        "#6435C9",
        "#A333C8",
    ];

    let color = "#cccccc";
    switch (true) {
        case value === 2:
            color = colorPallette[8];
            break;
        case value === 3:
            color = colorPallette[7];
            break;
        case value === -1:
            color = colorPallette[0];
            break;
        case value === 0:
            color = colorPallette[1];
            break;
        case value > 0 && value <= 0.17:
            color = colorPallette[2];
            break;
        // case value > 0.2 && value <= 0.4:
        //   color = colorPallette[3]
        //   group = 4
        //   break;
        case value > 0.17 && value <= 0.5:
            color = colorPallette[4];
            break;
        case value > 0.5 && value <= 0.75:
            color = colorPallette[5];
            break;
        case value > 0.75 && value <= 1:
            color = colorPallette[6];
            break;
        default:
            color = "#cccccc";
    }
    return color;
}
