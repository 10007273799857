import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import {
    Autocomplete,
    Box,
    InputAdornment,
    Icon,
    Typography,
    Grid,
    TextField,
} from '@mui/material';

import SearchIcon from "@mui/icons-material/Search";

import { debounce } from '@mui/material/utils';

import {
    // sendSearch,
    // addToSymptomsList,
    // addToClarificationsList,
    // addToPatientEditDisease,
    // addToPatientEditClarificationsList,
    // sendGetSymptomsFromText,
    addToEventsList,
    selectProtocolPatern,
} from "features/pages/pagesSlice";


// const SEARCHTEST = "рвота, тошнота, боль в животе, боль в левом подреберье, гипертрофия миокарда правого желудочка (ГПЖ), стеноз легочного ствола подклапанный,  дефект межжелудочковой перегородки";

const SearchComponent = ({ type, resultItemProps = {}, label = "", item = {}, itemIcon = "", showLevel = false, inputColor = "", variant = "inline", sx = {} }) => {
    const dispatch = useDispatch();

    const [value, setValue] = useState(null);
    const [optionsDisabled, setOptionsDisabled] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const protocolPatern = useSelector(selectProtocolPatern);


    console.log(protocolPatern);

    // protocolPatern.elements.forEach(element => {
    //     if (element.parents) {
    //         console.log(element.name, element.parents);
    //     }
    // });

    const handleKeyPress = (event) => {
        if (event.code === "Enter" || event.key === "Enter" || event.keyCode === "13") {
            if (!isHighlighted && variant === "parse") {
                // dispatch(sendGetSymptomsFromText({ data: { text: inputValue } }));
                console.log("search text - " + inputValue);
                event.preventDefault();
                // event.preventDefault();
            }
        }
    }

    // const fetch = useMemo(
    //     () =>
    //         debounce((request, success) => {
    //             // dispatch(sendSearch({
    //             //     data: {
    //             //         ...request,
    //             //         conceptId: item.id || null,
    //             //         type
    //             //     },
    //             //     success,
    //             // }));

    //             let res;
    //             res = protocolPatern.elements.filter(element => {
    //                 return element.name.toUpperCase().includes(request.text.toUpperCase());
    //             });

    //             return res;
    //             console.log(res);
    //             console.log(request);
    //             console.log(item);
    //             console.log(success);
    //         }, 400),
    //     [dispatch, item.id, type],
    // );

    useEffect(() => {
        let active = true;


        if (inputValue === '' || inputValue.length <= MIN_SEARCH_TEXT) {
            setOptions(value ? [value] : []);
            return undefined;
        }

        const wordsCount = (inputValue.match(/\S+/g) || []).length;

        console.log(wordsCount);

        if (wordsCount > MAX_SEARCH_WORDS) {
            setOptions([]);
            return undefined;
        }

        let results;
        results = Object.keys(protocolPatern.elements).filter(key => {
            return protocolPatern.elements[key].name.toUpperCase().includes(inputValue.toUpperCase()) && !protocolPatern.elements[key].parents;
        });

        // fetch({ text: inputValue }, (results) => {

        console.log(results);
        console.log(active);
        if (active) {
            let newOptions = [];

            if (value) {
                newOptions = [value];
            }

            if (results) {
                newOptions = [...newOptions, ...results];
            }

            console.log(newOptions);
            setOptions(newOptions);
        }
        // });

        return () => {
            active = false;
        };
    }, [value, inputValue, protocolPatern.elements]); // fetch


    return <>
        <Autocomplete
            fullWidth
            filterSelectedOptions
            id="search1"
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.name
            }
            filterOptions={(x) => x}
            options={optionsDisabled ? [] : options}
            freeSolo={variant === "parse"}
            inputValue={inputValue}
            value={value}
            sx={{
                outline: `4px solid ${inputColor}`,
                borderRadius: "5px",
                outlineOffset: "-4px",
                ...sx
            }}
            noOptionsText={"нет результатов"}

            onHighlightChange={(_, options) => {
                setIsHighlighted(!!options?.name);
            }}
            onChange={(_, newValue) => {
                if (type === "Symptoms") {
                    console.log("Symptoms");
                    console.log(newValue);
                    dispatch(addToEventsList({
                        ...protocolPatern.elements[newValue],
                        publicId: newValue,
                        value: null,
                        clarifications: [],
                        libId: 0,
                        type: 0,
                    }));
                } else if (type === "Details" || type === "Localization") {
                    console.log("Details || Localization");
                    // dispatch(addToClarificationsList({ item, value: newValue, type }));
                } else if (type === "Disease") {
                    console.log("Disease");
                    // dispatch(addToPatientEditDisease(newValue));
                } else if (type === "DiseaseDetails") {
                    console.log("DiseaseDetails");
                    // dispatch(addToPatientEditClarificationsList({ item, value: newValue, type }));
                }

                setInputValue("");
                setValue(null);
            }}
            onKeyDownCapture={handleKeyPress}
            onInputChange={(_, newInputValue) => {

                const regex = /(\r\n|\r|\n)/gm;
                const checkOptionsDisabled = regex.test(newInputValue);

                if (checkOptionsDisabled !== optionsDisabled) {
                    setOptionsDisabled(checkOptionsDisabled);
                }

                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    // component={"input"}
                    label={label}
                    fullWidth
                    // startAdornment= {<InputAdornment position="start">$</InputAdornment>}
                    // endAdornment= {<InputAdornment position="start">$</InputAdornment>}
                    multiline={variant === "parse"}

                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                        // endAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            )}

            renderOption={(props, option) => {
                return (
                    <li {...props} key={props.id}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 30 }}>
                                {itemIcon}
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Box component="span" {...resultItemProps}>
                                    {protocolPatern.elements[option].name}
                                </Box>
                                {showLevel && protocolPatern.elements[option].levelName ? <Typography variant="body2" color="text.secondary">
                                    {protocolPatern.elements[option].levelName}
                                </Typography> : null}
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    </>;
}

export const Search = memo(SearchComponent);
